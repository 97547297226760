import { action, makeObservable, observable, runInAction } from "mobx";
import { getErrorMessage } from "../utils/Networking";
import { getFeedDealDetail, listFeedDeals } from "./logic/feed";

type DescribableDealId = {
  dealId?: string;
};

type Query = {
  limit: number;
  offset: number;
  seed?: number;
};

class FeedStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  feedDeals = {
    data: [] as any,
    metadata: [],
    next: () => {},
  };

  @observable
  loading = {
    feedDeals: false,
    feedDealDetail: false,
  };

  @observable
  error = {
    feedDeals: false,
    feedDealDetail: null,
  };

  @observable
  feedDealDetail = {};

  @action
  public setFeedDealsLoading = (loading: boolean) => {
    this.loading.feedDeals = loading;
  };

  @action
  listFeedDeals = async (query: Query) => {
    try {
      this.setFeedDealsLoading(true);
      const result = await listFeedDeals({
        limit: query.limit,
        offset: query.offset,
        seed: query.seed,
      });
      if (query.offset >= query.limit) {
        runInAction(() => {
          const prevData = this.feedDeals.data;
          this.feedDeals = {
            ...this.feedDeals,
            ...result,
            data: [...prevData, ...result.data],
          };
        });
      } else {
        runInAction(() => {
          this.feedDeals = { ...result, next: () => {} };
        });
      }
      runInAction(() => {
        this.feedDeals.next = () => {
          this.listFeedDeals({
            limit: query.limit,
            offset: query.offset + query.limit,
            seed: result.metadata.seed,
          });
        };
      });

      return result;
    } catch (error) {
      this.error.feedDeals = getErrorMessage(error);
    } finally {
      this.setFeedDealsLoading(false);
    }
  };

  @action
  getFeedDealDetail = async (fn: DescribableDealId) => {
    try {
      this.feedDealDetail = {};
      this.loading.feedDealDetail = true;
      this.error.feedDealDetail = null;
      const feedDealDetail = await getFeedDealDetail({ dealId: fn.dealId });
      runInAction(() => {
        this.feedDealDetail = feedDealDetail;
      });
    } catch (error) {
      this.error.feedDealDetail = getErrorMessage(error);
    } finally {
      runInAction(() => {
        this.loading.feedDealDetail = false;
      });
    }
  };
}

export const feedStore = new FeedStore();
