import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../../fonts/color";
import { devices } from "../../../../../fonts/device";
import { TEXT } from "../../../../../fonts/text";
import { FeedDeal } from "../../../../../types/feed";

import picklePattern from "../../../../../assets/images/PicklesPattern.png";

interface TagProps {
  tag: "Popular" | "High Reach" | "" | undefined;
}

interface Props {
  deal: FeedDeal;
}

const Cap2 = styled(TEXT.caption2)`
  color: ${COLOR.WHITE};
`;

const Cap2Bold = styled(TEXT.caption2BOLD)`
  color: ${COLOR.WHITE};
`;

const Container = styled.div`
  position: relative;

  width: 100%;
  height: 250px;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const CampaignImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BrandInfo = styled.div`
  display: flex;
  position: absolute;
  top: 18px;
  left: 22px;
  align-items: center;
`;

const BrandIcon = styled.img`
  width: 44px;
  height: 44px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: solid ${COLOR.WHITE} 1px;
  border-radius: 50px;
`;

const BrandName = styled(TEXT.body1BOLD)`
  color: ${COLOR.WHITE};
`;

const DealInfo = styled.div`
  position: absolute;
  top: 27px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Tag = styled.div<TagProps>`
  display: flex;
  width: ${(props) => (props.tag === "Popular" ? "80px" : "97px")};
  height: 26px;
  border-radius: 50px;
  background-color: ${(props) =>
    props.tag === "Popular" ? COLOR.PINK : COLOR.YELLOW};
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const InfluInfo = styled.div`
  position: absolute;
  bottom: 16px;
  right: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const InfluIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 75px;
  height: 35px;
  margin-bottom: 4px;
`;

const InfluIcon1 = styled.img`
  position: absolute;
  left: 0px;
  width: 35px;
  height: 35px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: ${COLOR.GREEN} solid 1px;
  border-radius: 50px;
  z-index: 100;
  overflow: hidden;
`;

const InfluIcon2 = styled.img`
  position: absolute;
  left: 23px;
  width: 35px;
  height: 35px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: ${COLOR.GREEN} solid 1px;
  border-radius: 50px;
  z-index: 90;
`;

const InfluIcon3 = styled.img`
  position: absolute;
  left: 40px;
  width: 35px;
  height: 35px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: ${COLOR.GREEN} solid 1px;
  border-radius: 50px;
  z-index: 80;
`;

function ImageContainer(props: Props) {
  const {
    brand,
    coverImg,
    tags,
    limit,
    startDate,
    dueDate,
    pastInfluencers,
    total,
  } = props.deal;
  const tagIcon = tags?.includes("Popular") ? "Popular" : "ReachFill";

  const tag = tags?.[0] === "" ? tags?.[1] : tags?.[0];

  const openLength = Math.round(
    (new Date(dueDate).getTime() - new Date(startDate).getTime()) /
      (1000 * 3600 * 24)
  );

  return (
    <Container>
      <CampaignImage src={coverImg} />
      <BrandInfo>
        <BrandIcon src={brand.img} />
        <BrandName>{brand.name}</BrandName>
      </BrandInfo>
      <DealInfo>
        {(tags?.includes("Popular") || tags?.includes("High Reach")) && (
          <Tag tag={tag}>
            <RXIcon name={tagIcon} color={COLOR.WHITE} size={20} />
            <Cap2Bold>{tag}</Cap2Bold>
          </Tag>
        )}

        <Cap2Bold>รับ {limit} คน</Cap2Bold>
        <Cap2>แคมแปญ {openLength} วัน</Cap2>
      </DealInfo>
      <InfluInfo>
        <InfluIconContainer>
          <InfluIcon1
            src={pastInfluencers[0].profile_picture_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = picklePattern;
            }}
          />
          <InfluIcon2
            src={pastInfluencers[1].profile_picture_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = picklePattern;
            }}
          />
          <InfluIcon3
            src={pastInfluencers[2].profile_picture_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = picklePattern;
            }}
          />
        </InfluIconContainer>
        <Cap2Bold>และอีก {total} คนสมัครแคมเปญนี้</Cap2Bold>
      </InfluInfo>
    </Container>
  );
}

export default ImageContainer;
