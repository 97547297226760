import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { toDateDue } from "../../../utils";
import { Timeline as TimelineType } from "./DealType";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  padding: 12px 22px;
`;

const SectionLabel = styled(TEXT.body1BOLD)`
  display: block;
  margin-bottom: 8px;
`;

const SubLabel = styled(TEXT.body2)`
  color: ${COLOR.DARKGRAY};
  margin-bottom: 3px;
`;

const Calendar = styled.div`
  display: flex;
  margin-bottom: 14px;
`;

const DateText = styled(TEXT.body1)`
  margin-left: 10px;
`;

const BeforePostBadge = styled.span<{ green?: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  min-height: 28px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: ${(props) => (props.green ? COLOR.LIGHTGREEN2 : "#ffefcc")};
  margin-bottom: 10px;
`;

const BadgeText = styled(TEXT.caption1)<{ green?: boolean }>`
  justify-self: flex-start;
  margin-left: 8.5px;
  color: ${(props) => (props.green ? COLOR.GREEN : "#d29101")};
`;

function Timeline(props: TimelineType) {
  const { startDate, dueDate, postingDate, submissionDate, brandApprove } =
    props;
  return (
    <Container>
      <SectionLabel>Timeline</SectionLabel>
      <SubLabel>ช่วงเวลาที่เปิด - ปิดรับสมัคร influencer</SubLabel>
      <Calendar>
        <RXIcon name="Calendar" size={18} color={COLOR.GREEN} />
        <DateText>
          {toDateDue(startDate)} - {toDateDue(dueDate)}
        </DateText>
      </Calendar>

      <SubLabel>ช่วงเวลาที่ Influencer โพสต์งานและส่งผลงาน</SubLabel>
      <Calendar>
        <RXIcon name="Calendar" size={18} color={COLOR.GREEN} />
        <DateText>
          {toDateDue(postingDate)} - {toDateDue(submissionDate)}
        </DateText>
      </Calendar>
      {brandApprove && (
        <BeforePostBadge>
          <RXIcon name="Alert" size={15} color="#D29101" />
          <BadgeText>ส่งให้แบรนด์ตรวจก่อนโพสต์</BadgeText>
        </BeforePostBadge>
      )}
      {!brandApprove && (
        <BeforePostBadge green>
          <RXIcon name="CheckCircle" size={15} color={COLOR.GREEN} />
          <BadgeText green>โพสต์รูปเองได้เลย ไม่ต้องส่งให้ตรวจก่อน</BadgeText>
        </BeforePostBadge>
      )}
    </Container>
  );
}

export default Timeline;
