import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import { TEXT } from "../../../fonts/text";
import DealDescriptions from "../../molecules/DealDetail/DealDescriptions";
import DealNegotiation from "../../molecules/DealDetail/DealNegotiation";
import DealReward from "../../molecules/DealDetail/DealReward";
import { ScopeOfWork, Timeline } from "../../molecules/DealDetail/DealType";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 954px;

  @media ${devices.mobile} {
    padding: 0;
  }
`;

const DownloadBtn = styled.button`
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 58px;
  padding: 13px 24px;
  border-radius: 50px;
  outline: none;
  border: none;
  background-color: ${COLOR.GREEN};

  transition: all 0.2s;
  :hover {
    cursor: pointer;
    transform: translateY(-3px);
  }

  :active {
    transform: translateY(1px);
  }

  :link,
  :visited {
    text-decoration: none;
  }

  @media ${devices.mobile} {
    align-self: center;
    height: 50px;
    width: 80%;
  }
`;

const DownloadText = styled(TEXT.header5BOLD)`
  color: ${COLOR.WHITE};
`;

interface Props {
  dealId: string | undefined;
  minFollower?: number;
  value?: number;
  cash?: number;
  negotationDetail: NegotationDetail;
  dealDescriptions: DealDescriptionsType;
  passedDueDate: boolean;
  platforms?: ("Instagram" | "Tiktok")[];
}

interface NegotationDetail {
  isNegotiateScopeOfWorks: boolean;
  isNegotiateCash: boolean;
  isNegotiateProductValue: boolean;
}

interface DealDescriptionsType {
  detail: string;
  productDetail?: string;
  customerGroup: CustomerGroup;
  benefits: BenefitsDetail;
  timeline: Timeline;
  scopeOfWork: ScopeOfWork;
}

interface CustomerGroup {
  gender: string;
  age: string;
  location: string;
}

interface BenefitsDetail {
  products?: Array<string>;
  productValue?: number;
  cash?: number;
}

function DealBody(props: Props) {
  const {
    dealId,
    minFollower,
    value,
    cash,
    negotationDetail,
    dealDescriptions,
    passedDueDate,
    platforms,
  } = props;

  const route = passedDueDate
    ? `rabbitx://Main`
    : `rabbitx://Deal?dealId=${dealId}`;

  const onPress = () => {
    window.location.href = route;

    setTimeout(() => {
      window.location.href = "https://linktr.ee/pickleregister";
    }, 500);
  };

  return (
    <Container>
      <DealReward cash={cash} value={value} minFollower={minFollower} />
      <DealNegotiation {...negotationDetail} />
      <DealDescriptions platforms={platforms} {...dealDescriptions} />
      <DownloadBtn onClick={onPress}>
        <DownloadText>ไปที่แอปพลิเคชั่น</DownloadText>
      </DownloadBtn>
    </Container>
  );
}

export default DealBody;
