import React from "react";
import styled from "styled-components";
import DealCover from "../../molecules/DealDetail/DealCover";
import ImageCarousel from "../../molecules/ImageCarousel";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface Props {
  images: Array<string>;
  title: string;
  category: string;
  brand: string;
  logo: string;
}

function DealHeader(props: Props) {
  const { images, title, category, brand, logo } = props;
  return (
    <Container>
      <DealCover title={title} category={category} brand={brand} logo={logo} />
      <ImageCarousel images={images} />
    </Container>
  );
}

export default DealHeader;
