export const COLOR = {
  BLACK: "#191414",
  DARKGRAY: "#999999",
  GRAY: "#CBCBCB",
  LIGHTGRAY: "#EAEAEA",
  WHITE: "#FFFFFF",
  OFFWHITE: "#F8F8F8",

  DARKGREEN: "#008F6B",
  GREEN: "#00D0AA",
  LIGHTGREEN: "#A9F6E3",
  LIGHTGREEN2: "#DDFFF7",

  DARKPINK: "#B23955",
  PINK: "#FF527A",
  LIGHTPINK: "#FFEBF0",
  LIGHTPINK2: "#FDDEE6",

  DARKYELLOW: "#D29101",
  YELLOW: "#FFC648",
  LIGHTYELLOW: "#FFE7B2",
  LIGHTYELLOW2: "#FFEFCC",

  BLUE: "#56CCF2",

  ORANGE: "#F2994A",
};
