import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../../fonts/color";
import { TEXT } from "../../../../../fonts/text";
import { FeedDeal } from "../../../../../types/feed";
import picklePattern from "../../../../../assets/images/PicklesPattern.png";

interface TagProps {
  tag: "Popular" | "High Reach" | "" | undefined;
}

interface Props {
  deal: FeedDeal;
}

const Body2Bold = styled(TEXT.body2BOLD)`
  margin-left: 6px;
  color: ${COLOR.WHITE};
`;

const Cap1 = styled(TEXT.caption1)`
  color: ${COLOR.WHITE};
`;

const Cap1Bold = styled(TEXT.caption1BOLD)`
  color: ${COLOR.WHITE};
`;

const Container = styled.div`
  position: relative;
  width: 60%;
  height: 420px;
`;

const HighLightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BrandInfo = styled.div`
  display: flex;
  position: absolute;
  top: 30px;
  left: 30px;
  align-items: center;
`;

const BrandIcon = styled.img`
  width: 70px;
  height: 70px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border-radius: 50px;
`;

const BrandName = styled(TEXT.header5BOLD)`
  color: ${COLOR.WHITE};
`;

const DealInfo = styled.div`
  position: absolute;
  top: 41px;
  right: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Tag = styled.div<TagProps>`
  display: flex;
  width: ${(props) => (props.tag === "Popular" ? "106px" : "117px")};
  height: 30px;
  border-radius: 50px;
  background-color: ${(props) =>
    props.tag === "Popular" ? COLOR.PINK : COLOR.YELLOW};
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`;

const InfluInfo = styled.div`
  position: absolute;
  bottom: 31px;
  right: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const InfluIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 96.43px;
  height: 45px;
  margin-bottom: 7px;
`;

const InfluIcon1 = styled.img`
  position: absolute;
  left: 0px;
  width: 45px;
  height: 45px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: ${COLOR.GREEN} solid 1px;
  border-radius: 50px;
  z-index: 100;
`;

const InfluIcon2 = styled.img`
  position: absolute;
  left: 29.57px;
  width: 45px;
  height: 45px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: ${COLOR.GREEN} solid 1px;
  border-radius: 50px;
  z-index: 90;
`;

const InfluIcon3 = styled.img`
  position: absolute;
  left: 51.43px;
  width: 45px;
  height: 45px;
  background-color: ${COLOR.WHITE};
  margin-right: 15px;
  border: ${COLOR.GREEN} solid 1px;
  border-radius: 50px;
  z-index: 80;
`;

function ImageContainer(props: Props) {
  const {
    brand,
    coverImg,
    tags,
    limit,
    startDate,
    dueDate,
    pastInfluencers,
    total,
  } = props.deal || {};

  if (!props.deal) {
    return <div></div>;
  }

  const tagIcon = tags?.includes("Popular") ? "Popular" : "ReachFill";

  const tag = tags?.[0] === "" ? tags?.[1] : tags?.[0];

  const openLength = Math.round(
    (new Date(dueDate).getTime() - new Date(startDate).getTime()) /
      (1000 * 3600 * 24)
  );

  return (
    <Container>
      <HighLightImage src={coverImg} />
      <BrandInfo>
        <BrandIcon src={brand.img} />
        <BrandName>{brand.name}</BrandName>
      </BrandInfo>
      <DealInfo>
        {(tags?.includes("Popular") || tags?.includes("High Reach")) && (
          <Tag tag={tag}>
            <RXIcon name={tagIcon} color={COLOR.WHITE} size={20} />
            <Body2Bold>{tag}</Body2Bold>
          </Tag>
        )}
        <Body2Bold>รับ {limit} คน</Body2Bold>
        <Cap1>แคมแปญ {openLength} วัน</Cap1>
      </DealInfo>
      <InfluInfo>
        <InfluIconContainer>
          <InfluIcon1
            src={pastInfluencers[0].profile_picture_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = picklePattern;
            }}
          />
          <InfluIcon2
            src={pastInfluencers[1].profile_picture_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = picklePattern;
            }}
          />
          <InfluIcon3
            src={pastInfluencers[2].profile_picture_url}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = picklePattern;
            }}
          />
        </InfluIconContainer>
        <Cap1Bold>และอีก {total} คนสมัครแคมเปญนี้</Cap1Bold>
      </InfluInfo>
    </Container>
  );
}

export default ImageContainer;
