import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ScopeOfWork as _ScopeOfWork } from "../../../class/ScopeOfWork";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import { TEXT } from "../../../fonts/text";
import { ScopeOfWork } from "./DealType";
import Hashtags from "./Hashtags";
import ImageDetails from "./ImageDetails";
import ImageExample from "./ImageExample";
import Todos from "./Todos";

const Container = styled.div`
  padding: 16px 22px;
  padding-right: 15px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const SectionLabel = styled(TEXT.body1BOLD)``;

const Tiktok = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 30px;
  background-color: ${COLOR.BLACK};
  border-radius: 20px;
`;

const Instagram = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 124px;
  height: 30px;
  background: linear-gradient(
    225deg,
    #4f5bd5 0.62%,
    #962fbf 23.93%,
    #d62976 48.93%,
    #fa7e1e 70.81%,
    #feda75 96.33%
  );
  border-radius: 20px;

  @media ${devices.mobile} {
  }
`;

const InstagramText = styled(TEXT.body2)`
  margin-left: 5px;
  color: ${COLOR.WHITE};
`;

function InfluTodos(
  props: ScopeOfWork & { platforms?: ("Instagram" | "Tiktok")[] }
) {
  const {
    IG_album_images,
    IG_album_post,
    IG_individual_post,
    IG_reels,
    IG_story,
    IG_tv,
    TK_video,
    exampleDetail,
    examples,
    hashtags,
    postMedias,
    platforms,
  } = props;

  const todos = {
    IG_album_images,
    IG_album_post,
    IG_individual_post,
    IG_reels,
    IG_story,
    IG_tv,
    TK_video,
    postMedias,
  };

  let scopeOfWork: _ScopeOfWork;

  if (postMedias && postMedias.length > 0) {
    scopeOfWork = new _ScopeOfWork(postMedias);
  } else {
    scopeOfWork = new _ScopeOfWork(todos);
  }

  const getPlatforms = () => {
    const pfs = [];
    if (!platforms || platforms.length === 0) {
      pfs.push("Instagram");
    } else if (platforms.length > 0) {
      platforms.forEach((pf) => pfs.push(pf));
    }
    return pfs;
  };

  return (
    <Container>
      <Header>
        <SectionLabel>สิ่งที่ Influencer ต้องทำ</SectionLabel>
        {getPlatforms().includes("Instagram") && (
          <Instagram>
            <RXIcon name="Instagram" size={20} color={COLOR.WHITE} />
            <InstagramText>Instagram</InstagramText>
          </Instagram>
        )}
        {getPlatforms().includes("Tiktok") && (
          <Tiktok>
            <RXIcon name={"Tiktok"} size={20} color={COLOR.WHITE} />
            <InstagramText>Tiktok</InstagramText>
          </Tiktok>
        )}
      </Header>
      <Todos scopeOfWork={scopeOfWork} />
      <Hashtags hashtags={hashtags} />
      <ImageExample images={examples} />
      <ImageDetails detail={exampleDetail} />
    </Container>
  );
}

export default InfluTodos;
