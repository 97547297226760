import { useState, useEffect } from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import { TEXT } from "../../../fonts/text";
import { numberWithCommas } from "../../../utils";
import useWindowDimensions from "../../../utils/hook/UseWindowDimensions";

const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 98px;
  margin-top: 33px;
  background-color: ${COLOR.WHITE};
  border: 1px solid ${COLOR.LIGHTGRAY};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media ${devices.mobile} {
    margin: 0 10px;
    margin-top: 20px;
  }
`;

const ProductValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31%;
  border-right: 1px solid ${COLOR.LIGHTGRAY};
`;

const Reward = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Follower = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31%;
  border-left: 1px solid ${COLOR.LIGHTGRAY};
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconAndValue = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled(TEXT.body1)`
  color: ${COLOR.DARKGRAY};

  @media ${devices.mobile} {
    font-size: 16px;
    line-height: 18px;
  }
`;

const ValueText = styled(TEXT.header5BOLD)<{ green?: boolean }>`
  color: ${(props) => (props.green ? COLOR.DARKGREEN : COLOR.DARKGRAY)};
  @media ${devices.mobile} {
    font-size: 20px;
    line-height: 22px;
  }
`;

interface Props {
  minFollower?: number;
  value?: number;
  cash?: number;
}

function DealReward(props: Props) {
  const { cash, minFollower, value } = props;
  const [isOnMobile, setIsOnMobile] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 678) {
      setIsOnMobile(true);
    } else {
      setIsOnMobile(false);
    }
  }, [width]);

  return (
    <Container>
      <ProductValue>
        <Group>
          <IconAndValue>
            {!!value && (
              <RXIcon
                name="MoneyBag"
                size={isOnMobile ? 20 : 32}
                color={COLOR.DARKGREEN}
              />
            )}
            <ValueText green={!!value}>
              {value ? numberWithCommas(value) : "-"}
            </ValueText>
          </IconAndValue>
          <Label>มูลค่าสินค้า</Label>
        </Group>
      </ProductValue>
      <Reward>
        <Group>
          <IconAndValue>
            <ValueText green={!!cash}>
              {" "}
              {cash ? `฿${numberWithCommas(cash)}` : "-"}
            </ValueText>
          </IconAndValue>
          <Label>เงินค่าตอบแทน</Label>
        </Group>
      </Reward>
      <Follower>
        <Group>
          <IconAndValue>
            <ValueText green={!!minFollower}>
              {minFollower ? numberWithCommas(minFollower) : "-"}
            </ValueText>
          </IconAndValue>
          <Label>Followers</Label>
        </Group>
      </Follower>
    </Container>
  );
}

export default DealReward;
