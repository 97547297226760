import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DealDetail from "./pages/DealDetail";
import TopCampaign from "./pages/TopCampaign";

function Navigator() {
  return (
    <BrowserRouter>
      <Routes>
        {/* main pickle web */}
        <Route path="/" element={<TopCampaign />} />
        <Route path="/deals/:id" element={<DealDetail />} />
        <Route path="*" element={<TopCampaign />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigator;
