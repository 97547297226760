import styled from "styled-components";
import { COLOR } from "./color";
import { FontFCIconicBold, FontFCIconicRegular } from "./font";

const commonTextStyle = styled.span`
  color: ${COLOR.BLACK};
`;

export const TEXT = {
  header1: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 50px;
    line-height: 52px;
  `,
  header2: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 42px;
    line-height: 44px;
  `,
  header3: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 38px;
    line-height: 40px;
  `,
  header4: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 34px;
    line-height: 36px;
  `,
  header5: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 30px;
    line-height: 32px;
  `,
  header6: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 24px;
    line-height: 22px;
  `,
  body1: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 20px;
    line-height: 22px;
  `,
  body2: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 18px;
    line-height: 20px;
  `,
  caption1: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 16px;
    line-height: 18px;
  `,
  caption2: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 14px;
    line-height: 16px;
  `,
  overline: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  `,
  button: styled(commonTextStyle)`
    font-family: ${FontFCIconicRegular};
    font-size: 18px;
    line-height: 20px;
  `,

  header1BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 50px;
    line-height: 52px;
  `,
  header2BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 42px;
    line-height: 44px;
  `,
  header3BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 38px;
    line-height: 40px;
  `,
  header4BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 34px;
    line-height: 36px;
  `,
  header5BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 30px;
    line-height: 32px;
  `,
  header6BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 24px;
    line-height: 22px;
  `,
  body1BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 20px;
    line-height: 22px;
  `,
  body2BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 18px;
    line-height: 20px;
  `,
  caption1BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 16px;
    line-height: 18px;
  `,
  caption2BOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 14px;
    line-height: 16px;
  `,
  overlineBOLD: styled(commonTextStyle)`
    font-family: ${FontFCIconicBold};
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
  `,
};
