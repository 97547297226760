import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import CampaignDetails from "./CampaignDetails";
import { ScopeOfWork, Timeline as TimelineType } from "./DealType";
import InfluTodos from "./InfluTodos";
import InfluWillReceive from "./InfluWillReceive";
import Timeline from "./Timeline";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  min-height: 100px;
  margin-top: 31px;
  justify-content: space-between;
  column-gap: 21px;

  @media ${devices.smallTablet} {
    flex-wrap: wrap;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 250px;

  @media ${devices.smallTablet} {
    width: 100%;
    min-width: 325px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 250px;

  @media ${devices.smallTablet} {
    width: 100%;
    min-width: 325px;
  }
`;

const EndLine = styled.div`
  border: 1px solid ${COLOR.LIGHTGRAY};
`;

interface Props {
  detail: string;
  productDetail?: string;
  customerGroup: CustomerGroup;
  benefits: BenefitsDetail;
  timeline: TimelineType;
  scopeOfWork: ScopeOfWork;
  platforms?: ("Instagram" | "Tiktok")[];
}

interface CustomerGroup {
  gender: string;
  age: string;
  location: string;
}

interface BenefitsDetail {
  products?: Array<string>;
  productValue?: number;
  cash?: number;
}

function DealDescriptions(props: Props) {
  const {
    detail,
    productDetail,
    customerGroup,
    benefits,
    timeline,
    scopeOfWork,
    platforms,
  } = props;
  return (
    <Container>
      <LeftContainer>
        <CampaignDetails
          detail={detail}
          productDetail={productDetail}
          customerGroup={customerGroup}
        />
        <EndLine />
        <InfluWillReceive {...benefits} />
        <EndLine />
        <Timeline {...timeline} />
      </LeftContainer>
      <RightContainer>
        <InfluTodos platforms={platforms} {...scopeOfWork} />
      </RightContainer>
    </Container>
  );
}

export default DealDescriptions;
