import axios, { AxiosError } from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const GET = async (
  url: string,
  onCatch?: (error: AxiosError<any>) => void
  //   authToken?: string
) => {
  try {
    let config = {
      headers: {
        // Authorization: authToken
        //   ? "Bearer " + (authToken || (await getAuthToken()))
        //   : "",
      },
    };

    const result = await axios.get(`${API_URL}${url}`, config);
    return result.data;
  } catch (error: any) {
    if (onCatch) onCatch(error);
    throw error;
  }
};

export const getErrorMessage = (error: AxiosError | any) => {
  if (error?.response?.data?.message) return error?.response?.data?.message;
  return error?.response?.data;
};
