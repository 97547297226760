import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";

const Container = styled.div`
  margin-top: 23px;
`;

const Title = styled(TEXT.body2)`
  color: ${COLOR.DARKGRAY};
`;

const Desc = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1;
  padding: 20px;
  border-radius: 12px;
  background-color: ${COLOR.OFFWHITE};
  min-height: 50px;
`;

const DescText = styled(TEXT.body1)`
  white-space: pre-wrap;
  line-height: 22px;
`;

interface Props {
  detail?: string;
}

function ImageDetails(props: Props) {
  const { detail } = props;

  if (detail?.trim.length === 0) {
    return null;
  }

  return (
    <Container>
      <Title>รายละเอียดภาพ</Title>
      <Desc>
        <DescText>{detail}</DescText>
      </Desc>
    </Container>
  );
}

export default ImageDetails;
