import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { devices } from "../../../../fonts/device";
import { FeedDeal } from "../../../../types/feed";
import ImageContainer from "./components/ImageContainer";
import InformationContainer from "./components/InformationContainer";

interface Props {
  deal: FeedDeal;
}

const CardContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  box-shadow: 1px 3px 5px #eaeaea;
  text-decoration: none;
  overflow: hidden;

  transition: all 0.2s;
  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  @media ${devices.mobile} {
    border-radius: 0px;
  }
`;

function CampaignCard(props: Props) {
  const { deal } = props;

  if (!props.deal) {
    return <div></div>;
  }

  const link = `/deals/${deal.dealId}`;

  return (
    <CardContainer to={link}>
      <ImageContainer deal={deal} />
      <InformationContainer deal={deal} />
    </CardContainer>
  );
}

export default CampaignCard;
