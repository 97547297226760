import { action, makeObservable, observable } from "mobx";

class TestStore {
  @observable
  number = 0;

  constructor() {
    makeObservable(this);
  }

  @action
  increment = () => {
    this.number++;
  };
}

export const testStore = new TestStore();
