import { inject, observer } from "mobx-react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { BeatLoader } from "react-spinners";
import styled from "styled-components";
import RLoading from "../components/atoms/RLoading";
import Footer from "../components/molecules/Layout/Footer";
import Header from "../components/molecules/Layout/Header";
import CampaignCard from "../components/molecules/TopCampaign/CampaignCards/CampaignCard";
import HighlightCampaign from "../components/molecules/TopCampaign/HighlightCampaign/HighlightCampaign";
import { COLOR } from "../fonts/color";
import { devices } from "../fonts/device";
import { TEXT } from "../fonts/text";
import { FeedDeal } from "../types/feed";
import useWindowDimensions from "../utils/hook/UseWindowDimensions";

interface Props {
  loading?: boolean;
  listFeedDeals?: (query: {
    limit: number;
    offset: number;
    seed?: number;
  }) => {};
  feedDeals?: any;
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(TEXT.header1BOLD)`
  margin-top: 58px;
  color: ${COLOR.GREEN};

  @media ${devices.mobile} {
    margin-top: 13px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 20px;
    line-height: 22px;
    border-bottom: 3px ${COLOR.GREEN} solid;
  }
`;

const CampaignContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 35px;
  width: 80%;
  max-width: 1200px;
  margin-top: 84px;

  @media (max-width: 1490px) {
    justify-content: space-evenly;
  }

  @media ${devices.mobile} {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    column-gap: 0px;
    row-gap: 0px;
    margin-top: 0px;
  }
`;

const LoadMoreButton = styled.button`
  width: 291px;
  height: 58px;
  background-color: ${COLOR.GREEN};
  border: none;
  border-radius: 50px;
  margin-top: 100px;
  cursor: pointer;
`;

const LoadingBar = styled.div`
  display: flex;
  justify-content: center;
  width: 291px;
  height: 58px;
  margin-top: 100px;
`;

const LoadMore = styled(TEXT.header5BOLD)`
  color: ${COLOR.WHITE};
`;

function TopCampaign(props: Props) {
  const { listFeedDeals, feedDeals, loading } = props;
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (
      listFeedDeals &&
      feedDeals.data.length === 0 &&
      !feedDeals.metadata.seed
    )
      listFeedDeals({ limit: 7, offset: 0 });
  }, [listFeedDeals, feedDeals]);

  if (feedDeals.data.length === 0) {
    return <RLoading />;
  }

  const highlightDeal = feedDeals.data[0];

  return (
    <div>
      <PageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Pickle แอปจับคู่ระหว่างแบรนด์กับอินฟลูเอนเซอร์"}</title>
          <meta
            name="description"
            content="แหล่งรวมงานรีวิวที่คุณเลือกเองได้ มาเจออินฟลูเอนเซอร์ที่ใช่สำหรับแบรนด์คุณ"
          />
          <meta
            property="og:title"
            content="Pickle แอปจับคู่ระหว่างแบรนด์กับอินฟลูเอนเซอร์"
          />
          <meta
            property="og:description"
            content="แหล่งรวมงานรีวิวที่คุณเลือกเองได้ มาเจออินฟลูเอนเซอร์ที่ใช่สำหรับแบรนด์คุณ"
          />
          <meta property="og:url" content="https://app.pickle.co.th/" />
          <meta
            property="og:image"
            content="https://storage.googleapis.com/rabbitx/1643365400858512GoogleIcon.png"
          />
          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="631616584326084" />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:title"
            content="Pickle แอปจับคู่ระหว่างแบรนด์กับอินฟลูเอนเซอร์"
          />
          <meta
            name="twitter:description"
            content="แหล่งรวมงานรีวิวที่คุณเลือกเองได้ มาเจออินฟลูเอนเซอร์ที่ใช่สำหรับแบรนด์คุณ"
          />
          <meta
            name="twitter:image"
            content="https://storage.googleapis.com/rabbitx/1643365400858512GoogleIcon.png"
          />
        </Helmet>

        <Header />
        <Title>Campaign on Pickle</Title>
        <HighlightCampaign deal={highlightDeal} />
        {width <= 1280 ? (
          <CampaignContainer>
            {feedDeals.data.slice(0).map((feedDeal: FeedDeal) => {
              return <CampaignCard key={feedDeal.dealId} deal={feedDeal} />;
            })}
          </CampaignContainer>
        ) : (
          <CampaignContainer>
            {feedDeals.data.slice(1).map((feedDeal: FeedDeal) => {
              return <CampaignCard key={feedDeal.dealId} deal={feedDeal} />;
            })}
          </CampaignContainer>
        )}
        {feedDeals.data.length < feedDeals.metadata.count && !loading && (
          <LoadMoreButton
            onClick={() => {
              feedDeals.next();
            }}
            disabled={loading}
          >
            <LoadMore>ดูเพิ่มเติม</LoadMore>
          </LoadMoreButton>
        )}
        {loading && (
          <LoadingBar>
            <BeatLoader color={COLOR.LIGHTGREEN} />
          </LoadingBar>
        )}
      </PageContainer>
      <Footer />
    </div>
  );
}

export default inject(({ feedStore }) => {
  return {
    loading: feedStore.loading.feedDeals,
    listFeedDeals: feedStore.listFeedDeals,
    feedDeals: feedStore.feedDeals,
  };
})(observer(TopCampaign));
