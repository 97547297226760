import React, { Fragment } from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import { TEXT } from "../../../fonts/text";

const Container = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80%;
  background: linear-gradient(
    180deg,
    #000000 69.23%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.2;
`;

const TextContainer = styled.div`
  align-self: center;
  position: absolute;
  top: 6%;
  z-index: 100;
  width: 66%;
  max-width: 1000px;
  height: 100px;
`;

const Category = styled(TEXT.header5)`
  color: ${COLOR.WHITE};
  padding-bottom: 9px;

  @media ${devices.mobile} {
    font-size: 18px;
    line-height: 20px;
  }
`;

const Title = styled(TEXT.header1BOLD)`
  color: ${COLOR.WHITE};

  @media ${devices.mobile} {
    font-size: 24px;
    line-height: 22px;
  }
`;

const Brand = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;

  @media ${devices.mobile} {
    margin-top: 10px;
  }
`;

const BrandImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 28px;

  @media ${devices.mobile} {
    width: 30px;
    height: 30px;

    margin-right: 10px;
  }
`;

const BrandName = styled(TEXT.header5BOLD)`
  color: ${COLOR.WHITE};

  @media ${devices.mobile} {
    font-size: 20px;
    line-height: 22px;
  }
`;

interface Props {
  title: string;
  category: string;
  logo: string;
  brand: string;
}

function DealCover(props: Props) {
  const { title, category, brand, logo } = props;
  return (
    <Fragment>
      <Container />
      <TextContainer>
        <div>
          <Category>{category}</Category>
        </div>
        <div>
          <Title>{title}</Title>
        </div>
        <Brand>
          <BrandImage src={logo} />
          <BrandName>{brand}</BrandName>
        </Brand>
      </TextContainer>
    </Fragment>
  );
}

export default React.memo(DealCover);
