import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../../fonts/color";
import { TEXT } from "../../../../../fonts/text";
import { FeedDeal } from "../../../../../types/feed";
import { numberWithCommas } from "../../../../../utils";
import DealValues from "../../../../atoms/DealValues";

interface Props {
  deal: FeedDeal;
}

const Container = styled.div`
  position: relative;
  width: 40%;
  height: 420px;
  padding: 41px 53px;
`;

const Type = styled(TEXT.header6)`
  color: ${COLOR.DARKGRAY};
`;

const Title = styled(TEXT.header4BOLD)``;

const ValueContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

const LikeCommentContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 40px;
  left: 56px;
  width: 75%;
  align-items: center;
  border-top: solid ${COLOR.GRAY} 1px;
  padding-top: 20px;
`;

const LikeComment = styled(TEXT.body1)`
  margin-left: 7px;
  margin-right: 70px;
  color: ${COLOR.DARKGRAY};
`;

function InformationContainer(props: Props) {
  const { name, category, value, cash, followers } = props.deal || {};

  const { likes, comments } = props.deal.analysis || {};

  return (
    <Container>
      <Type>{category}</Type>
      <br />
      <Title>{name}</Title>
      <ValueContainer>
        <DealValues
          type={"value"}
          amount={numberWithCommas(value)}
          description={"มููลค่าสินค้า"}
          highlight={true}
        />
        <DealValues
          type={"cash"}
          amount={numberWithCommas(cash)}
          description={"เงินค่าตอบแทน"}
          highlight={true}
        />
        <DealValues
          type={"follower"}
          amount={numberWithCommas(followers)}
          description={"Followers"}
          highlight={true}
        />
      </ValueContainer>
      <LikeCommentContainer>
        <RXIcon name={"HeartFill"} color={COLOR.PINK} size={30} />
        <LikeComment>{numberWithCommas(likes)}</LikeComment>
        <RXIcon name={"Comment"} color={COLOR.BLUE} size={30} />
        <LikeComment>{numberWithCommas(comments)}</LikeComment>
      </LikeCommentContainer>
    </Container>
  );
}

export default InformationContainer;
