export const provinces = [
  {
    label: "ทุกจังหวัด",
    value: "all",
  },
  {
    label: "กรุงเทพมหานคร",
    value: "Bangkok",
  },
  {
    label: "สมุทรปราการ",
    value: "Samut Prakarn",
  },
  {
    label: "นนทบุรี",
    value: "Nonthaburi",
  },
  {
    label: "ปทุมธานี",
    value: "Pathum Thani",
  },
  {
    label: "พระนครศรีอยุธยา",
    value: "Phra Nakhon Si Ayutthaya",
  },
  {
    label: "อ่างทอง",
    value: "Ang Thong",
  },
  {
    label: "ลพบุรี",
    value: "Lop Buri",
  },
  {
    label: "สิงห์บุรี",
    value: "Sing Buri",
  },
  {
    label: "ชัยนาท",
    value: "Chai Nat",
  },
  {
    label: "สระบุรี",
    value: "Saraburi",
  },
  {
    label: "ชลบุรี",
    value: "Chon Buri",
  },
  {
    label: "ระยอง",
    value: "Rayong",
  },
  {
    label: "จันทบุรี",
    value: "Chanthaburi",
  },
  {
    label: "ตราด",
    value: "Trat",
  },
  {
    label: "ฉะเชิงเทรา",
    value: "Chachoengsao",
  },
  {
    label: "ปราจีนบุรี",
    value: "Prachin Buri",
  },
  {
    label: "นครนายก",
    value: "Nakhon Nayok",
  },
  {
    label: "สระแก้ว",
    value: "Sa kaeo",
  },
  {
    label: "นครราชสีมา",
    value: "Nakhon Ratchasima",
  },
  {
    label: "บุรีรัมย์",
    value: "Buri Ram",
  },
  {
    label: "สุรินทร์",
    value: "Surin",
  },
  {
    label: "ศรีสะเกษ",
    value: "Si Sa Ket",
  },
  {
    label: "อุบลราชธานี",
    value: "Ubon Ratchathani",
  },
  {
    label: "ยโสธร",
    value: "Yasothon",
  },
  {
    label: "ชัยภูมิ",
    value: "Chaiyaphum",
  },
  {
    label: "อำนาจเจริญ",
    value: "Amnat Charoen",
  },
  {
    label: "บึงกาฬ",
    value: "Bueng Kan",
  },
  {
    label: "หนองบัวลำภู",
    value: "Nong Bua Lam Phu",
  },
  {
    label: "ขอนแก่น",
    value: "Khon Kaen",
  },
  {
    label: "อุดรธานี",
    value: "Udon Thani",
  },
  {
    label: "เลย",
    value: "Loei",
  },
  {
    label: "หนองคาย",
    value: "Nong Khai",
  },
  {
    label: "มหาสารคาม",
    value: "Maha Sarakham",
  },
  {
    label: "ร้อยเอ็ด",
    value: "Roi Et",
  },
  {
    label: "กาฬสินธุ์",
    value: "Kalasin",
  },
  {
    label: "สกลนคร",
    value: "Sakon Nakhon",
  },
  {
    label: "นครพนม",
    value: "Nakhon Phanom",
  },
  {
    label: "มุกดาหาร",
    value: "Mukdahan",
  },
  {
    label: "เชียงใหม่",
    value: "Chiang Mai",
  },
  {
    label: "ลำพูน",
    value: "Lamphun",
  },
  {
    label: "ลำปาง",
    value: "Lampang",
  },
  {
    label: "อุตรดิตถ์",
    value: "Uttaradit",
  },
  {
    label: "แพร่",
    value: "Phrae",
  },
  {
    label: "น่าน",
    value: "Nan",
  },
  {
    label: "พะเยา",
    value: "Phayao",
  },
  {
    label: "เชียงราย",
    value: "Chiang Rai",
  },
  {
    label: "แม่ฮ่องสอน",
    value: "Mae Hong Son",
  },
  {
    label: "นครสวรรค์",
    value: "Nakhon Sawan",
  },
  {
    label: "อุทัยธานี",
    value: "Uthai Thani",
  },
  {
    label: "กำแพงเพชร",
    value: "Kamphaeng Phet",
  },
  {
    label: "ตาก",
    value: "Tak",
  },
  {
    label: "สุโขทัย",
    value: "Sukhothai",
  },
  {
    label: "พิษณุโลก",
    value: "Phitsanulok",
  },
  {
    label: "พิจิตร",
    value: "Phichit",
  },
  {
    label: "เพชรบูรณ์",
    value: "Phetchabun",
  },
  {
    label: "ราชบุรี",
    value: "Ratchaburi",
  },
  {
    label: "กาญจนบุรี",
    value: "Kanchanaburi",
  },
  {
    label: "สุพรรณบุรี",
    value: "Suphan Buri",
  },
  {
    label: "นครปฐม",
    value: "Nakhon Pathom",
  },
  {
    label: "สมุทรสาคร",
    value: "Samut Sakhon",
  },
  {
    label: "สมุทรสงคราม",
    value: "Samut Songkhram",
  },
  {
    label: "เพชรบุรี",
    value: "Phetchaburi",
  },
  {
    label: "ประจวบคีรีขันธ์",
    value: "Prachuap Khiri Khan",
  },
  {
    label: "นครศรีธรรมราช",
    value: "Nakhon Si Thammarat",
  },
  {
    label: "กระบี่",
    value: "Krabi",
  },
  {
    label: "พังงา",
    value: "Phang-nga",
  },
  {
    label: "ภูเก็ต",
    value: "Phuket",
  },
  {
    label: "สุราษฎร์ธานี",
    value: "Surat Thani",
  },
  {
    label: "ระนอง",
    value: "Ranong",
  },
  {
    label: "ชุมพร",
    value: "Chumphon",
  },
  {
    label: "สงขลา",
    value: "Songkhla",
  },
  {
    label: "สตูล",
    value: "Satun",
  },
  {
    label: "ตรัง",
    value: "Trang",
  },
  {
    label: "พัทลุง",
    value: "Phatthalung",
  },
  {
    label: "ปัตตานี",
    value: "Pattani",
  },
  {
    label: "ยะลา",
    value: "Yala",
  },
  {
    label: "นราธิวาส",
    value: "Narathiwat",
  },
];
