import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import useWindowDimensions from "../../../utils/hook/UseWindowDimensions";

const Container = styled.div`
  margin-top: 23px;
`;

const Title = styled(TEXT.body2)`
  color: ${COLOR.DARKGRAY};
`;

const ImageContainer = styled.div`
  margin-top: 6px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const Image = styled.img<{ isZoom: boolean; width: number }>`
  width: ${(props) => (props.isZoom ? "100%" : "100px")};
  height: ${(props) => (props.isZoom ? "auto" : "100px")};
  margin-right: ${(props) => (props.isZoom ? 0 : "4px")};
  margin-bottom: 6px;
  border-radius: ${(props) => (props.isZoom ? "30px" : "8px")};
  padding: ${(props) => (props.isZoom ? "20px" : 0)};
  object-fit: cover;

  :hover {
    cursor: pointer;
  }
`;

interface Props {
  images: Array<string>;
}

function ImageExample(props: Props) {
  const { images } = props;
  const [isZoom, setIsZoom] = useState(false);
  const { width } = useWindowDimensions();

  const handleImgLoad = useCallback(() => {
    setIsZoom(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoom(shouldZoom);
  }, []);

  if (images?.length === 0) return null;

  const imagesComp = images?.map((image, index) => (
    <ControlledZoom
      isZoomed={isZoom}
      key={image + index.toString()}
      onZoomChange={handleZoomChange}
    >
      <Image
        isZoom={isZoom}
        width={width}
        src={image}
        onClick={handleImgLoad}
      />
    </ControlledZoom>
  ));

  return (
    <Container>
      <Title>ตัวอย่างภาพ</Title>
      <ImageContainer>{imagesComp}</ImageContainer>
      {/* {isZoom && ( */}
      {/* <ControlledZoom
        isZoomed={isZoom}
        onZoomChange={(shouldZoom) => setIsZoom(shouldZoom)}
      >
        <img src={images[imageIndex]} />
      </ControlledZoom> */}
      {/* )} */}
    </Container>
  );
}

export default ImageExample;
