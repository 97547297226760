import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../../../fonts/color";
import { devices } from "../../../../../fonts/device";
import { TEXT } from "../../../../../fonts/text";
import { FeedDeal } from "../../../../../types/feed";
import { numberWithCommas } from "../../../../../utils";
import DealValues from "../../../../atoms/DealValues";

interface Props {
  deal: FeedDeal;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 375px;
  height: 170px;
  padding: 18px 23px 22px;

  @media ${devices.mobile} {
    width: 100%;
    height: 190px;
    padding-top: 38px;
  }
`;

const Type = styled(TEXT.caption2)`
  color: ${COLOR.DARKGRAY};
`;

const Title = styled(TEXT.body1BOLD)``;

const ValueContainer = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 12px;
`;

const LikeCommentContainer = styled.div`
  display: flex;
  position: absolute;
  left: 23px;
  bottom: 16px;
  align-items: center;
`;

const LikeComment = styled(TEXT.caption2)`
  margin-left: 5px;
  margin-right: 70px;
  color: ${COLOR.DARKGRAY};
`;

const SeeMore = styled.button`
  position: absolute;
  bottom: 16px;
  right: 21px;
  border: none;
  background-color: ${COLOR.WHITE};
`;

const SeeMoreText = styled(TEXT.caption2)`
  color: ${COLOR.DARKGREEN};
  text-decoration: underline;
`;

function InformationContainer(props: Props) {
  const { name, category, value, cash, followers } = props.deal || {};

  const { likes, comments } = props.deal.analysis || {};

  return (
    <Container>
      <Type>{category}</Type>
      <Title>{name}</Title>
      <ValueContainer>
        <DealValues
          type={"value"}
          amount={numberWithCommas(value)}
          description={"มููลค่าสินค้า"}
          highlight={false}
        />
        <DealValues
          type={"cash"}
          amount={numberWithCommas(cash)}
          description={"เงินค่าตอบแทน"}
          highlight={false}
        />
        <DealValues
          type={"follower"}
          amount={numberWithCommas(followers)}
          description={"Followers"}
          highlight={false}
        />
      </ValueContainer>
      <LikeCommentContainer>
        <RXIcon name={"HeartFill"} color={COLOR.PINK} size={16} />
        <LikeComment>{numberWithCommas(likes)}</LikeComment>
        <RXIcon name={"Comment"} color={COLOR.BLUE} size={16} />
        <LikeComment>{numberWithCommas(comments)}</LikeComment>
      </LikeCommentContainer>
      <SeeMore>
        <SeeMoreText>ดูเพิ่มเติม</SeeMoreText>
      </SeeMore>
    </Container>
  );
}

export default InformationContainer;
