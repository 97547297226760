import React, { Fragment } from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import { TEXT } from "../../../fonts/text";

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px 16px;
  min-height: 70px;
  border-radius: 8px;
  margin-top: 38px;
  border: 1px solid ${COLOR.LIGHTGRAY};

  @media ${devices.mobile} {
    margin: 0 10px;
    min-height: 50px;
    margin-top: 20px;
  }
`;

const Badge = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 9px 26px;
  border-radius: 25px;
  background-color: ${COLOR.ORANGE};
  margin-right: 35px;

  @media ${devices.mobile} {
    height: 30px;
    padding: 9px 16px;
    margin-right: 15px;
  }
`;

const BadgeText = styled(TEXT.header6BOLD)`
  color: ${COLOR.WHITE};

  @media ${devices.mobile} {
    //body2BOLD
    font-size: 18px;
    line-height: 20px;
  }
`;

const TextConatiner = styled.div``;

const Text = styled(TEXT.header6)`
  margin-right: 5px;
  @media ${devices.mobile} {
    //body2
    font-size: 18px;
    line-height: 20px;
  }
`;

interface Props {
  isNegotiateScopeOfWorks: boolean;
  isNegotiateCash: boolean;
  isNegotiateProductValue: boolean;
}

function DealNegotiation(props: Props) {
  const {
    isNegotiateCash = false,
    isNegotiateProductValue = false,
    isNegotiateScopeOfWorks = false,
  } = props;

  let dealNegoTypes = [];
  if (isNegotiateProductValue) dealNegoTypes.push("มูลค่าสินค้า");
  if (isNegotiateCash) dealNegoTypes.push("เงินค่าตอบแทน");
  if (isNegotiateScopeOfWorks) dealNegoTypes.push("รายละเอียดงาน");

  if (dealNegoTypes.length === 0) {
    return null;
  }

  // const textComp =

  return (
    <Container>
      <Badge>
        <BadgeText>ยื่นข้อเสนอได้</BadgeText>
      </Badge>
      <TextConatiner>
        {dealNegoTypes.map((negoType, index) => {
          const lastIndex = dealNegoTypes.length - 1;
          return (
            <Fragment key={negoType}>
              <Text>{negoType}</Text>
              {lastIndex !== index ? <Text> • </Text> : null}
            </Fragment>
          );
        })}
      </TextConatiner>
    </Container>
  );
}

export default DealNegotiation;
