import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";
import { numberWithCommas } from "../../../utils";

const Container = styled.div`
  margin-top: 32px;
  padding: 12px 22px;
  margin-bottom: 32px;
`;

const SectionLabel = styled(TEXT.body1BOLD)`
  display: block;
  margin-bottom: 16px;
`;

const Lists = styled.ul`
  margin-bottom: 12px;
`;

const List = styled.li`
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const ListTitle = styled(TEXT.body1)``;
const ListValue = styled(TEXT.body1)``;

const BadgeGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ReceiveBadge = styled.span`
  display: flex;
  align-items: center;
  min-height: 28px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: ${COLOR.LIGHTGREEN2};
  margin-right: 10px;
  margin-bottom: 10px;
`;

const BadgeText = styled(TEXT.caption1)`
  margin-left: 8.5px;
  color: ${COLOR.DARKGREEN};
`;

interface Props {
  products?: Array<string>;
  productValue?: number;
  cash?: number;
}

function InfluWillReceive(props: Props) {
  const { products = [], productValue, cash } = props;

  const productsList = products.map((prod, index) => {
    return (
      <List key={prod + index}>
        <ListTitle>{prod}</ListTitle>
        {index === products.length - 1 && (
          <ListValue>{numberWithCommas(productValue || 0)} บาท</ListValue>
        )}
      </List>
    );
  });

  return (
    <Container>
      <SectionLabel>สิ่งที่ Influencer จะได้รับ</SectionLabel>
      <Lists>
        {products.length > 0 && productsList}
        {!!cash && cash !== 0 && (
          <List>
            <ListTitle>เงินค่าตอบแทน</ListTitle>
            <ListValue>{numberWithCommas(cash)} บาท</ListValue>
          </List>
        )}
      </Lists>
      <BadgeGroup>
        {products.length > 0 && (
          <ReceiveBadge>
            <RXIcon name="CheckCircle" size={15} color={COLOR.GREEN} />
            <BadgeText>ได้รับสินค้า</BadgeText>
          </ReceiveBadge>
        )}
        {!!cash && cash !== 0 && (
          <ReceiveBadge>
            <RXIcon name="CheckCircle" size={15} color={COLOR.GREEN} />
            <BadgeText>ได้รับเงินค่าตอบแทน</BadgeText>
          </ReceiveBadge>
        )}
      </BadgeGroup>
    </Container>
  );
}

export default React.memo(InfluWillReceive);
