export function objectToQuerystring(
  obj: Record<string, string | number | boolean | undefined>
) {
  const qs = new URLSearchParams(removeEmpty(obj) as Record<string, string>);
  return `?${qs.toString()}`;
}

export function removeEmpty(obj: Record<string, any>) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

export const numberWithCommas = (x: string | number) => {
  if (!x) {
    return "0";
  }
  let s = x;
  if (typeof x === "number") {
    if (x % 1 !== 0) s = x.toFixed(2);
  }
  return s?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toDateDue = (dueDate: string | Date, withComma = false) => {
  if (!dueDate) {
    return "";
  }
  var month = [];
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  let date;
  if (typeof dueDate === typeof "") {
    const dateString = dueDate as string;
    date = new Date(dateString.split("+")?.[0]);
  } else {
    date = new Date(dueDate);
  }
  return `${("0" + date.getDate()).slice(-2)}${withComma ? "," : ""} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;
};
