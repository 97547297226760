import { objectToQuerystring } from "../../utils";
import { GET } from "../../utils/Networking";

export const listFeedDeals = async ({
  offset = 0,
  limit = 7,
  seed,
}: {
  offset: number;
  limit: number;
  seed?: number;
}) => {
  const queries = { offset, limit, seed };

  const qs = objectToQuerystring(queries);
  const { metadata, data } = await GET(`/brands/feeds/deals${qs}`);
  return {
    metadata:
      metadata.length > 0
        ? metadata[0]
        : { count: 0, limit: 0, offset: 0, seed: Math.random() },
    data,
  };
};

type DescribableDealId = {
  dealId?: string;
};

export const getFeedDealDetail = async (fn: DescribableDealId) => {
  if (!fn.dealId) {
    throw new Error("dealId is undefined");
  }
  return GET(`/brands/feeds/deals/${fn.dealId}`);
};
