import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../assets/images/PickleLogoWhite.png";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  background-color: ${COLOR.GREEN};
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled(Link)`
  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 34px;

  @media ${devices.mobile} {
    width: 106.69px;
    height: 28.35px;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <ImageContainer to={"/"}>
        <Image src={logo} alt="logo" />
      </ImageContainer>
    </HeaderContainer>
  );
}

export default Header;
