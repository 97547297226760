import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { testStore } from "./stores/testStore";
import { Provider } from "mobx-react";
import Navigator from "./Navigator";
import GlobalFonts from "./fonts/font";
import { feedStore } from "./stores/feedStore";
import { HelmetProvider } from "react-helmet-async";
const Root = () => {
  return (
    <HelmetProvider>
      <Provider testStore={testStore} feedStore={feedStore}>
        <GlobalFonts />
        <Navigator />
      </Provider>
    </HelmetProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
