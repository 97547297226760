import React from "react";
import styled from "styled-components";

import FreeImg from "../../../assets/images/FreeLogo.png";
import PickleBrand from "../../../assets/images/PickleBrand.png";
import PickleInflu from "../../../assets/images/Pickle.png";
import footer from "../../../assets/images/Footer_Crop.png";
import { TEXT } from "../../../fonts/text";
import { COLOR } from "../../../fonts/color";
import { devices } from "../../../fonts/device";
import useWindowDimensions from "../../../utils/hook/UseWindowDimensions";

const Container = styled.footer`
  padding: 0 10px;
  margin-top: 174px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 308px;

  background-image: url(${footer});
  background-size: cover;
  background-repeat: no-repeat;

  column-gap: 20px;

  @media ${devices.smallTablet} {
    margin-top: 80px;
    column-gap: 18px;
    min-height: 150px;
  }

  @media ${devices.mobile} {
    min-height: 0px;
    height: 82px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 100px;

  @media (max-width: 1080px) {
    margin-right: 40px;
  }

  @media ${devices.smallTablet} {
    margin-right: 48px;
  }

  @media ${devices.mobile} {
    margin-right: 27px;
  }
`;

const FooterTextLarge = styled(TEXT.header1BOLD)`
  font-size: 72px;
  line-height: 52px;
  color: ${COLOR.WHITE};

  @media ${devices.smallTablet} {
    //h2Bold
    font-size: 42px;
    line-height: 44px;
  }

  @media ${devices.mobile} {
    //h6Bold
    font-size: 20px;
    line-height: 22px;
  }
`;

const FooterTextSmall = styled(TEXT.header3BOLD)`
  color: ${COLOR.WHITE};
  margin-top: 15px;
  padding-bottom: 7px;
  border-bottom: dashed ${COLOR.WHITE} 1px;

  @media ${devices.smallTablet} {
    //h5Bold
    margin-top: 0px;
    padding-bottom: 0px;
    font-size: 30px;
    line-height: 32px;
  }

  @media ${devices.mobile} {
    //body1Bold
    font-size: 11px;
    line-height: 16px;
  }
`;

const FooterTextSmaller = styled(TEXT.header5BOLD)`
  color: ${COLOR.WHITE};

  @media ${devices.smallTablet} {
    //h6Bold
    font-size: 24px;
    line-height: 22px;
  }
`;

const FooterImageContainer = styled.div`
  margin-top: 10px;

  @media ${devices.mobile} {
    margin-top: 5px;
  }
`;

const FreeLogo = styled.img`
  width: 177px;
  height: 43px;
  margin-left: 15px;

  @media ${devices.smallTablet} {
    width: 136px;
    height: 33px;
  }

  @media ${devices.mobile} {
    width: 101px;
    height: 22px;
    margin-left: 0px;
  }
`;

const Logos = styled.div`
  display: flex;
`;

const LogoContainer = styled.a`
  width: 200px;
  height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #00d0aa;
  border: 1px dashed #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  margin-right: 15px;

  transition: all 0.2s;
  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  :link,
  :visited {
    text-decoration: none;
  }

  @media ${devices.smallTablet} {
    width: 120px;
    height: 120px;
    margin-right: 11px;

    border-radius: 10px;
  }

  @media ${devices.mobile} {
    width: 54px;
    height: 52px;

    border-radius: 10px;
  }
`;

const LogoImage = styled.img`
  width: 120px;
  height: 120px;

  @media ${devices.smallTablet} {
    width: 60px;
    height: 60px;
  }

  @media ${devices.mobile} {
    width: 32px;
    height: 32px;
  }
`;

const UnderIconText = styled(TEXT.header6BOLD)`
  color: ${COLOR.WHITE};

  @media ${devices.mobile} {
    font-size: 12px;
    line-height: 12px;
  }
`;

function Footer() {
  const { width } = useWindowDimensions();

  return (
    <Container>
      <TextContainer>
        <FooterTextLarge>Match Maker</FooterTextLarge>
        <FooterTextSmall>for Influencers & Brands</FooterTextSmall>
        <FooterImageContainer>
          {width > 678 && <FooterTextSmaller>สมัครเลยวันนี้</FooterTextSmaller>}
          <FreeLogo src={FreeImg} alt="Free" />
        </FooterImageContainer>
      </TextContainer>

      <Logos>
        <LogoContainer
          target={"_blank"}
          href="http://brands.picklebutnotcucumber.com/www"
        >
          <LogoImage src={PickleBrand} alt="Pickle Brand Logo" />
          <UnderIconText>{width > 850 && "Pickle for "}Brand</UnderIconText>
        </LogoContainer>
        <LogoContainer
          target={"_blank"}
          href="http://influencer.picklebutnotcucumber.com/www"
        >
          <LogoImage src={PickleInflu} alt="Pickle Brand Logo" />
          <UnderIconText>
            {width > 850 && "Pickle for "}Influencer
          </UnderIconText>
        </LogoContainer>
      </Logos>
    </Container>
  );
}

export default Footer;
