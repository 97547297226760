import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../fonts/color";
import { TEXT } from "../../../fonts/text";

const Container = styled.div`
  margin-top: 28px;
`;

const Title = styled(TEXT.body2)`
  display: block;
  color: ${COLOR.DARKGRAY};
  margin-bottom: 6px;
`;

const HashtagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const Hashtag = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31px;

  padding: 0 10px;

  border: 1px solid ${COLOR.BLUE};
  border-radius: 50px;
  margin-right: 5px;
  margin-bottom: 10px;

  :hover {
    cursor: pointer;
  }
`;

const HashtagText = styled(TEXT.body1)`
  color: ${COLOR.BLUE};
`;

interface Props {
  hashtags?: Array<string>;
}

function Hashtags(props: Props) {
  const { hashtags } = props;

  if (!hashtags || hashtags?.length === 0) {
    return null;
  }

  const hashtagsComp = hashtags?.map((hashtag, index) => (
    <Hashtag key={index}>
      <HashtagText>#{hashtag}</HashtagText>
    </Hashtag>
  ));

  return (
    <Container>
      <Title>#Hashtag</Title>
      <HashtagContainer>{hashtagsComp}</HashtagContainer>
    </Container>
  );
}

export default Hashtags;
