import React from "react";
import styled from "styled-components";
import { TEXT } from "../../../fonts/text";
import { provinces } from "../../../utils/Provinces";

const Container = styled.div`
  padding: 12px 22px;
`;

const Section = styled.div`
  margin-bottom: 29px;
`;

const SectionLabel = styled(TEXT.body1BOLD)`
  display: block;
  margin-bottom: 8px;
`;

const Paragraph = styled(TEXT.body1)`
  display: block;
  white-space: pre-wrap;
`;

interface Props {
  detail: string;
  productDetail?: string;
  customerGroup: CustomerGroup;
}

interface CustomerGroup {
  gender: string;
  age: string;
  location: string;
}

const genderOptions = [
  { label: "ทุกเพศ", value: "both" },
  { label: "หญิง", value: "female" },
  { label: "ชาย", value: "male" },
];

function CampaignDetails(props: Props) {
  const { detail, productDetail, customerGroup } = props;

  const { gender, age, location } = customerGroup;

  const targetGender = genderOptions.find((gen) => gen.value === gender);

  const province = provinces.find((prov) => prov.value === location);
  return (
    <Container>
      <Section>
        <SectionLabel>รายละเอียดแคมเปญ</SectionLabel>
        <Paragraph>{detail}</Paragraph>
      </Section>
      {!!productDetail && (
        <Section>
          <SectionLabel>รายละเอียดสินค้า/บริการ/โปรโมชั่น</SectionLabel>
          <Paragraph>{productDetail}</Paragraph>
        </Section>
      )}
      <Section>
        <SectionLabel>กลุ่มลูกค้าของแบรนด์</SectionLabel>
        <Paragraph>เพศ : {targetGender?.label}</Paragraph>
        <Paragraph>อายุ : {age}</Paragraph>
        <Paragraph>จังหวัด : {province?.label}</Paragraph>
      </Section>
    </Container>
  );
}

export default CampaignDetails;
