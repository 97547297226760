import React, { useEffect, useState } from "react";
import { RXIcon } from "rn-rx-icons";
import styled, { keyframes } from "styled-components";
import { COLOR } from "../../fonts/color";
import { devices } from "../../fonts/device";
import useWindowDimensions from "../../utils/hook/UseWindowDimensions";

const Container = styled.div``;

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 700px;
  background-color: white;
  overflow: hidden;
`;

const breatheAnimation = keyframes`
    0% { opacity: 0.4, }
    30% { opacity: 0.6 }
    40% { opacity: 0.8 }
    100% { opacity: 1 }
 `;

const Image = styled.img`
  width: 100%;
  max-height: 700px;
  object-fit: cover;
  backface-visibility: hidden;
  animation-name: ${breatheAnimation};
  animation-duration: 1s;
`;

const PrevBtn = styled.button`
  position: absolute;
  top: 45%;
  left: 56px;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 30px;
  border: none;

  background-color: white;

  transition: all 0.2s;
  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  :active {
    transform: translateY(1px);
  }

  @media ${devices.mobile} {
    width: 23px;
    height: 23px;

    left: 20px;
  }
`;

const NextBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 46px;
  height: 46px;
  background-color: white;
  z-index: 100;
  top: 45%;
  right: 56px;
  border-radius: 30px;
  border: none;
  transition: all 0.2s;

  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  :active {
    transform: translateY(1px);
  }

  @media ${devices.mobile} {
    width: 23px;
    height: 23px;

    right: 20px;
  }
`;

const Dots = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: 29px;
  margin-left: 10px;

  @media ${devices.mobile} {
    bottom: 15px;
  }
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #c4c4c4;
  margin-right: 10px;

  transition: all 0.1s;
  :hover {
    cursor: pointer;
    transform: translateY(-1px);
  }
  :active {
    transform: translateY(1px);
  }

  @media ${devices.mobile} {
    width: 6px;
    height: 6px;

    margin-right: 6px;
  }
`;

const SelectedDot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  margin-right: 10px;

  transition: all 0.1s;
  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }

  :active {
    transform: translateY(1px);
  }

  @media ${devices.mobile} {
    width: 6px;
    height: 6px;

    margin-right: 6px;
  }
`;

interface Props {
  images: Array<string>;
}

function ImageCarousel(props: Props) {
  const { images } = props;

  const [imageIndex, setImageIndex] = useState(0);
  const [isOnMobile, setIsOnMobile] = useState(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width <= 678) {
      setIsOnMobile(true);
    } else {
      setIsOnMobile(false);
    }
  }, [width]);

  if (!images) {
    return null;
  }

  const dotsComps = images.map((item, index) => {
    if (index === imageIndex) {
      return (
        <SelectedDot
          key={index}
          role={"button"}
          onClick={() => dotClickHandler(index)}
        />
      );
    }
    return (
      <Dot key={index} role={"button"} onClick={() => dotClickHandler(index)} />
    );
  });

  const prevClickHandler = () => {
    setImageIndex((prev) => {
      if (prev === 0) {
        return images.length - 1;
      }
      return prev - 1;
    });
  };

  const nextClickHandler = () => {
    setImageIndex((prev) => {
      if (prev === images.length - 1) {
        return 0;
      }
      return prev + 1;
    });
  };

  const dotClickHandler = (index: number) => {
    setImageIndex(index);
  };

  return (
    <Container>
      <CarouselContainer>
        <Image src={images[imageIndex]} alt="images" />
        {images.length > 1 && (
          <PrevBtn onClick={prevClickHandler}>
            <RXIcon
              name="ArrowShortLeft"
              size={isOnMobile ? 16 : 24}
              color={COLOR.DARKGRAY}
            />
          </PrevBtn>
        )}
        {images.length > 1 && (
          <NextBtn onClick={nextClickHandler}>
            <RXIcon
              name="ArrowShortRight"
              size={isOnMobile ? 16 : 24}
              color={COLOR.DARKGRAY}
            />
          </NextBtn>
        )}
        <Dots>{dotsComps}</Dots>
      </CarouselContainer>
    </Container>
  );
}

export default ImageCarousel;
