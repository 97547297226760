import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { COLOR } from "../../fonts/color";
import { TEXT } from "../../fonts/text";

interface Props {
  type: "value" | "cash" | "follower";
  amount: number | string;
  description: string;
  highlight: boolean;
}

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HighlightDisplayValue = styled(TEXT.header5BOLD)`
  color: ${COLOR.DARKGREEN};
`;

const HighlightDescription = styled(TEXT.body1)`
  color: ${COLOR.DARKGRAY};
`;

const DisplayValue = styled(TEXT.body2BOLD)`
  color: ${COLOR.DARKGREEN};
`;

const Description = styled(TEXT.caption2)`
  color: ${COLOR.DARKGRAY};
`;

function DealValues(props: Props) {
  const { type, amount, description, highlight } = props;

  const displayAmount = amount === 0 || amount === "0" ? "-" : amount;

  const DealType = () => {
    if (highlight) {
      if (type === "value") {
        return (
          <ValueContainer>
            <RXIcon name="MoneyBag" color={COLOR.DARKGREEN} size={20} />
            <HighlightDisplayValue>{displayAmount}</HighlightDisplayValue>
          </ValueContainer>
        );
      } else if (type === "cash") {
        return (
          <ValueContainer>
            <HighlightDisplayValue>฿ {displayAmount}</HighlightDisplayValue>
          </ValueContainer>
        );
      } else {
        return (
          <ValueContainer>
            <HighlightDisplayValue>{displayAmount}</HighlightDisplayValue>
          </ValueContainer>
        );
      }
    } else {
      if (type === "value") {
        return (
          <ValueContainer>
            <RXIcon name="MoneyBag" color={COLOR.DARKGREEN} size={20} />
            <DisplayValue>{displayAmount}</DisplayValue>
          </ValueContainer>
        );
      } else if (type === "cash") {
        return (
          <ValueContainer>
            <DisplayValue>฿ {displayAmount}</DisplayValue>
          </ValueContainer>
        );
      } else {
        return (
          <ValueContainer>
            <DisplayValue>{displayAmount}</DisplayValue>
          </ValueContainer>
        );
      }
    }
  };
  return (
    <div>
      {DealType()}
      {highlight ? (
        <HighlightDescription>{description}</HighlightDescription>
      ) : (
        <Description>{description}</Description>
      )}
    </div>
  );
}

export default DealValues;
