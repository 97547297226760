import { createGlobalStyle } from "styled-components";
import FCIconicRegular from "./FC-Iconic-Regular.ttf";
import FCIconicBold from "./FC-Iconic-Bold.ttf";
import FCIconicItalic from "./FC-Iconic-Italic.ttf";
import FCIconicBoldItalic from "./FC-Iconic-Bold-Italic.ttf";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  @font-face {
    font-family: 'FC Iconic';
    src: url(${FCIconicRegular}) format("truetype");
  }
  @font-face {
    font-family: 'FC Iconic Bold';
    src: url(${FCIconicBold}) format("truetype");
  }
  @font-face {
    font-family: 'FC Iconic Italic';
    src: url(${FCIconicItalic}) format("truetype");
  }
  @font-face {
    font-family: 'FC Iconic Bold Italic';
    src: url(${FCIconicBoldItalic}) format("truetype");
  }
`;

export const FontFCIconicRegular = "FC Iconic";

export const FontFCIconicBold = "FC Iconic Bold";
