import React from "react";
import { RXIcon } from "rn-rx-icons";
import styled from "styled-components";
import { ScopeOfWork } from "../../../class/ScopeOfWork";
import { TEXT } from "../../../fonts/text";

const Lists = styled.ul`
  list-style: none;
`;

const List = styled.li`
  display: flex;
  flex: 1;
  align-items: center;
  margin-bottom: 10px;
`;

const Title = styled(TEXT.body1)`
  margin-left: 10px;
`;

const Amount = styled(TEXT.body1)`
  margin-left: auto;
  margin-right: 8px;
`;

interface Props {
  scopeOfWork: ScopeOfWork;
}

function Todos(props: Props) {
  const { scopeOfWork } = props;

  const {
    IG_Reels,
    IG_TV,
    IG_album_posts,
    IG_individual_image_posts,
    IG_individual_video_posts,
    IG_story,
  } = scopeOfWork.toDisplayObject();

  const albumMediasComp = IG_album_posts?.map((post) => {
    const { image, video } = post;
    let text = "";
    if (image > 0 && video > 0) {
      text = `(${image} รูป, ${video} วีดีโอ)`;
    } else if (image > 0) {
      text = `(${image} รูป)`;
    } else if (video > 0) {
      text = `(${video} วีดีโอ)`;
    }
    return (
      <List key={post.id}>
        <RXIcon name="Multi" size={18} />
        <Title>Multi Post {text}</Title>
        <Amount>1 โพสต์</Amount>
      </List>
    );
  });

  const storyMediasComp = IG_story?.map((post) => {
    const { image, video } = post;
    const text = image > 0 ? "รูป" : "วีดีโอ";
    return (
      <List>
        <RXIcon name="StoryCircle" size={18} />
        <Title>Story ({text})</Title>
        <Amount>{image + video} สตอรี่</Amount>
      </List>
    );
  });

  return (
    <Lists>
      {IG_individual_image_posts > 0 && (
        <List>
          <RXIcon name="Image" size={18} />
          <Title>Single Post (รูป) </Title>
          <Amount>{IG_individual_image_posts} โพสต์</Amount>
        </List>
      )}
      {IG_individual_video_posts > 0 && (
        <List>
          <RXIcon name="Image" size={18} />
          <Title>Single Post (วีดีโอ) </Title>
          <Amount>{IG_individual_video_posts} โพสต์</Amount>
        </List>
      )}
      {albumMediasComp.length > 0 && albumMediasComp}
      {storyMediasComp.length > 0 && storyMediasComp}
      {IG_TV > 0 && (
        <List>
          <RXIcon name="TV" size={18} />
          <Title>IG TV </Title>
          <Amount>{IG_TV} โพสต์</Amount>
        </List>
      )}
      {IG_Reels > 0 && (
        <List>
          <RXIcon name="Reels" size={18} />
          <Title>Reels </Title>
          <Amount>{IG_Reels} โพสต์</Amount>
        </List>
      )}
    </Lists>
  );
}

export default Todos;
