import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";
import RLoading from "../components/atoms/RLoading";
import Footer from "../components/molecules/Layout/Footer";
import Header from "../components/molecules/Layout/Header";
import DealBody from "../components/organisms/DealDetail/DealBody";
import DealHeader from "../components/organisms/DealDetail/DealHeader";
import { Helmet } from "react-helmet-async";
import { inject, observer } from "mobx-react";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface Props {
  getFeedDealDetail?: (dealId: { dealId: string }) => {};
  feedDealDetail?: any;
  loading?: any;
}

function DealDetail(props: Props) {
  const params = useParams();
  const navigate = useNavigate();
  const { getFeedDealDetail, feedDealDetail, loading } = props;

  const { id } = params;

  useEffect(() => {
    if (id && getFeedDealDetail) {
      getFeedDealDetail({ dealId: id });
    }
  }, [id, getFeedDealDetail]);

  useEffect(() => {
    if (!loading && !feedDealDetail) {
      navigate("/");
    }
  }, [loading, feedDealDetail, navigate]);

  if (loading || !feedDealDetail) {
    return <RLoading />;
  }
  const {
    photos,
    name,
    category,
    criteria,
    value,
    cash,
    isNegotiateProductValue = false,
    isNegotiateCash = false,
    isNegotiateScopeOfWorks = false,
    detail,
    gender,
    age,
    location,
    startDate,
    dueDate,
    postingDate,
    submissionDate,
    brandApprove,
    posts,
    hashtags,
    benefits,
    brandName,
    brandImage,
    platforms,
  } = feedDealDetail;

  const NegotationDetail = {
    isNegotiateScopeOfWorks,
    isNegotiateCash,
    isNegotiateProductValue,
  };

  const dealDesc = {
    detail,
    customerGroup: {
      gender,
      age,
      location,
    },
    benefits: {
      products: benefits,
      productValue: value,
      cash,
    },
    timeline: {
      startDate,
      dueDate,
      postingDate,
      submissionDate,
      brandApprove,
    },
    scopeOfWork: {
      ...posts,
      hashtags,
    },
  };

  let metaImage;
  if (photos?.length > 0) {
    metaImage = photos[0];
  }

  const passedDueDate = new Date().getTime() > new Date(dueDate).getTime();

  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{name}</title>

        <meta
          property="og:url"
          content={`https://app.pickle.co.th/deals/${id}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={name} />
        <meta property="og:description" content={detail} />
        <meta property="og:image:secure_url" content={metaImage || null} />
        <meta property="fb:app_id" content="631616584326084" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={name} />
        <meta
          name="twitter:description"
          content={`https://app.pickle.co.th/deals/${id}`}
        />
        <meta name="twitter:image" content={metaImage || null} />
      </Helmet>
      <Header />
      <DealHeader
        images={photos}
        title={name}
        category={category}
        brand={brandName}
        logo={brandImage}
      />
      <DealBody
        dealId={id}
        minFollower={criteria?.minFollower}
        value={value}
        cash={cash}
        negotationDetail={NegotationDetail}
        dealDescriptions={dealDesc}
        passedDueDate={passedDueDate}
        platforms={platforms}
      />
      <Footer />
    </Page>
  );
}

export default inject(({ feedStore }) => {
  return {
    getFeedDealDetail: feedStore.getFeedDealDetail,
    feedDealDetail: feedStore.feedDealDetail,
    loading: feedStore.loading.feedDealDetail,
  };
})(observer(DealDetail));
