import React from "react";
import styled from "styled-components";

import { BounceLoader } from "react-spinners";
import { COLOR } from "../../fonts/color";
import Header from "../molecules/Layout/Header";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`;

const Space = styled.div`
  margin-top: 50px;
`;

function RLoading() {
  return (
    <Container>
      <Header />
      <Space />
      <BounceLoader color={COLOR.LIGHTGREEN} />
    </Container>
  );
}

export default RLoading;
