import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FeedDeal } from "../../../../types/feed";
import useWindowDimensions from "../../../../utils/hook/UseWindowDimensions";
import ImageContainer from "./components/ImageContainer";
import InformationContainer from "./components/InformationContainer";

interface Props {
  deal: FeedDeal;
}

const Container = styled(Link)`
  display: inline-flex;
  margin-top: 58px;
  width: 80%;
  max-width: 1200px;
  border-radius: 18px;
  box-shadow: 1px 3px 5px #eaeaea;
  text-decoration: none;
  overflow: hidden;

  transition: all 0.2s;
  :hover {
    cursor: pointer;
    transform: translateY(-2px);
  }
`;

function HighlightCampaign(props: Props) {
  const { deal } = props;
  const { width } = useWindowDimensions();

  if (!props.deal) {
    return <div></div>;
  }

  const link = `/deals/${deal.dealId}`;

  if (width > 1280) {
    return (
      <Container to={link}>
        <ImageContainer deal={deal} />
        <InformationContainer deal={deal} />
      </Container>
    );
  } else {
    return <div></div>;
  }
}

export default HighlightCampaign;
